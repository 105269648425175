import axios from 'axios';
import JwtService from "@/core/services/jwt.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    //LIVE SERVER
    //1//chokrojan.com
    //2//saintmartinhyundai.net
    //3//hanifenterprise.com
    //4//sarbickparibahan.com
    //5//parklinetransport.com
    //6//ktchanifbd.com
    //7//uniqueservice.xyz
    //8//safelineparibahan.com

    //LIVE SERVER
    let domain_name = window.location.hostname;
    axios.defaults.baseURL = "https://"+domain_name+"/api/v1/";
    axios.defaults.headers.common["domain-name"] = domain_name;

    //TEST SERVER
    //axios.defaults.baseURL = "http://18.136.9.47:1800/api/v1/";
    //LOCAL SERVER
    //axios.defaults.baseURL = "http://127.0.0.1:8007/api/v1/";

    //axios.defaults.headers.common["domain-name"] = "chokrojan.com";

    axios.defaults.headers.common["user-platform"] = "1";
    axios.defaults.headers.common["Authorization"] = `Bearer ${JwtService.getToken()}`;
    axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Content-Type'] ='application/json';

    axios.interceptors.request.use((config) => {
      let companyInfo = JwtService.getCompanyInfo();
      if(companyInfo != undefined){
        config.headers['company-id'] = companyInfo.id;
        if(companyInfo.id==1){
          config.headers['user-platform'] = "3";
        }
      }
      if(JwtService.getToken()){
        config.headers["Authorization"] = `Bearer ${JwtService.getToken()}`;
      }

      return config;
    }, (error) => {
      // if (DEBUG) { console.error("✉️ ", error); }
      return Promise.reject(error);
    });

  },

};

export default ApiService;
// export const requestInterceptor = function () {
//   axios.interceptors.request.use(function(config) {
//     if(config.url!=API.auth.signIn){
//       if(!mainStore.getters.loggedIn){
//         toastService.showErrorToast('Please login again');
//         mainStore.dispatch('logOut');
//         router.push('/pages/login');
//       }else {
//         config.headers["Authorization"] = "Bearer "+localStorage.token;
//       }
//
//     }
//     return config;
//   }, function(err) {
//     return Promise.reject(err);
//   });
// };
//
// export const responseInterceptor =  function (){
//   axios.interceptors.response.use(function (response) {
//     return response;
//   }, function (error) {
//     if(error.response.status == 401){
//       mainStore.dispatch('logOut');
//       router.push('/pages/login');
//     }
//     return Promise.reject(error);
//   });
//
// };
