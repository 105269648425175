import JwtService from '@/core/services/jwt.service';

//mutations

export const MUTATION_SET_TOKEN = 'setToken';
export const MUTATION_SAVE_USER_INFO = 'saveUserInfo';
export const MUTATION_SAVE_COMPANY_INFO = 'saveCompanyInformation';
export const MUTATION_SAVE_COMPNAY_WEB_CONTENT = 'saveCompanyWebContent';

//actions
export const ACTION_SET_TOKEN = 'setToken';
export const ACTION_IS_LOGGED_IN = 'isLoggedIn';
export const ACTION_SAVE_COMPANY_INFO = 'saveCompanyInformation';
export const ACTION_SAVE_COMPANY_WEB_CONTENT = 'saveCompanyWebContent';
export const ACTION_SAVE_PROFILE_INFO = 'saveProfileInformation';
export const AUTH_LOGOUT = 'authLogout';

const state = {
    errors :{},
    isLoggedIn : !!JwtService.getToken(),
    companyInfo : JwtService.getCompanyInfo(),
    companyWebContent : JwtService.getCompanyWebContent(),
    userInfo : null
};

const getters = {
    isLoggedIn:(state)=> {
        return state.isLoggedIn
    },
    getCompanyInfo: (state)=>{
        return state.companyInfo
    },
    getCompanyWebContent: (state)=>{
        return state.companyWebContent
    },
    userInfo: (state)=>{
        return state.userInfo
    }
};


const mutations = {
    [MUTATION_SET_TOKEN](state, tokenResponse) {
        //console.log(tokenResponse);
        state.isLoggedIn = true;
        //console.log(state.isLoggedIn);
        state.errors = {};
        JwtService.saveToken(tokenResponse.access_token);
    },
    [MUTATION_SAVE_COMPANY_INFO](state,companyInfo){
        JwtService.saveCompanyInfo(companyInfo);
        state.companyInfo = companyInfo;
    },
    [MUTATION_SAVE_COMPNAY_WEB_CONTENT](state,companyWebContent){
        JwtService.saveCompanyWebContent(companyWebContent);
        state.companyWebContent = companyWebContent;
    },
    [MUTATION_SAVE_USER_INFO](state,tokenResponse){
        //console.log("In mutation")
        state.userInfo = tokenResponse.user;
        JwtService.saveProfileInfo(tokenResponse.user);
    },
    [AUTH_LOGOUT](state,tokenResponse){
        state.userInfo = null;
        state.isLoggedIn = false;
        JwtService.destroyToken();
        JwtService.destroyProfileInfo();
    }


};

const actions = {
    [ACTION_SET_TOKEN](context,tokenResponse){
        //console.log("action hoise");
        context.commit(MUTATION_SET_TOKEN,tokenResponse);
        context.commit(MUTATION_SAVE_USER_INFO,tokenResponse);
    },
    [ACTION_IS_LOGGED_IN](context){
        if(JwtService.getToken()) return true;
        return false;
    },
    [ACTION_SAVE_COMPANY_INFO](context,companyInformation){
        context.commit(MUTATION_SAVE_COMPANY_INFO,companyInformation);
    },
    [ACTION_SAVE_COMPANY_WEB_CONTENT](context,companyWebContent){
        context.commit(MUTATION_SAVE_COMPNAY_WEB_CONTENT,companyWebContent);
    },
    [ACTION_SAVE_PROFILE_INFO](context,profileInformation){
        //console.log(profileInformation)
        let response = {};
        response.user = profileInformation;
        context.commit(MUTATION_SAVE_USER_INFO,response);
    },
    [AUTH_LOGOUT](context,res){
        context.commit(AUTH_LOGOUT,res);
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};

