import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
const Layout = () => import(/* webpackChunkName: "layout" */ "@/view/layout/Layout");

export default new Router({
  mode:'history',
  routes: [
    {
      path: "/",
      component: Layout,
      children: [
        {
          path: "/",
          name: "Online Bus Ticket",
          component: () => import(/* webpackChunkName: "db" */ "@/view/pages/Dashboard.vue")
        },
      ]
    },
    {
      path: "/profile",
      component: Layout,
      children: [
        {
          path: "/",
          name: "Profile",
          redirect:"/profile/dashboard",
          component: () => import(/* webpackChunkName: "pf" */ "@/view/pages/profile/Profile.vue"),
          children: [
            {
              path: "dashboard",
              name: "Profile Dashboard",
              component: () => import(/* webpackChunkName: "pfdb" */ "@/view/pages/profile/ProfileDashboard.vue")
            },
            {
              path: "tickets",
              name: "My Tickets",
              component: () => import(/* webpackChunkName: "mt" */ "@/view/pages/profile/MyTickets.vue")
            },
            {
              path: "edit",
              name: "Profile Edit",
              component: () => import(/* webpackChunkName: "pe" */ "@/view/pages/profile/ProfileEdit.vue")
            },
            {
              path : "ticket-details/:id",
              name : "ticket-details",
              component : () => import(/* webpackChunkName: "td" */ "@/view/pages/profile/TicketDetails.vue")
            }
          ]
        },
      ]
    },
    {
      path: "/login",
      component: Layout,
      children: [
        {
          name: "Passenger Login",
          path: "/",
          component: () => import(/* webpackChunkName: "lg" */ "@/view/pages/auth/Login")
        }
      ]
    },
    {
      path: "/signup",
      component: Layout,
      children: [
        {
          name: "Signup",
          path: "/",
          component: () => import(/* webpackChunkName: "rg" */ "@/view/pages/auth/Register")
        }
      ]
    },
    {
      path: "/privacy-policy",
      component: Layout,
      children: [
        {
          name: "Privacy Policy",
          path: "/",
          component: () => import(/* webpackChunkName: "pp" */ "@/view/pages/PrivacyPolicy")
        }
      ]
    },
    {
      path: "/terms-of-use",
      component: Layout,
      children: [
        {
          name: "Terms Of Use",
          path: "/",
          component: () => import(/* webpackChunkName: "tu" */ "@/view/pages/TermsOfUse")
        }
      ]
    },
    {
        path: "/adminpanel",
        name: "AdminPanel",
        component: () => import(/* webpackChunkName: "ap" */ "@/view/pages/AdminPanel.vue")
    },
    {
      // the 404 route, when none of the above matches
      path: "*",
      component: Layout,
      children: [
        {
          name: "404 Page not found",
          path: "/",
          alias: "/404",
          component: () => import(/* webpackChunkName: "er" */ "@/view/pages/error/Error-6.vue")
        },
      ]
    }
  ]
});
