const ID_TOKEN_KEY = "id_token";
const COMPANY_INFORMATION = "company_information";
const COMPANY_WEB_CONTENT = "company_web_content";
const PROFILE_INFORMATION = "profile_information";
export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const destroyProfileInfo = () => {
    window.localStorage.removeItem(PROFILE_INFORMATION);
};

export const saveProfileInfo = profileInfo => {
    window.localStorage.setItem(PROFILE_INFORMATION,JSON.stringify(profileInfo));
};

export const getProfileInfo = () => {
    let profileInfo = JSON.parse(window.localStorage.getItem(PROFILE_INFORMATION));
    if(!profileInfo || profileInfo == null) return null;
    return profileInfo;

    // return a;
};


export const saveCompanyInfo = companyInfo => {
  window.localStorage.setItem(COMPANY_INFORMATION,JSON.stringify(companyInfo));
};
export const saveCompanyWebContent = companyWebContent => {
    window.localStorage.setItem(COMPANY_WEB_CONTENT,JSON.stringify(companyWebContent));
};

export const getCompanyInfo = () => {
  let companyInfo = JSON.parse(window.localStorage.getItem(COMPANY_INFORMATION));
  if(!companyInfo || companyInfo == null) return null;
  return companyInfo;
};

export const getCompanyWebContent = () => {
    let companyWebContent = JSON.parse(window.localStorage.getItem(COMPANY_WEB_CONTENT));
    if(!companyWebContent || companyWebContent == null) return null;
    return companyWebContent;
};

export default { getToken, saveToken, destroyToken,saveCompanyInfo,saveCompanyWebContent,saveProfileInfo,getCompanyInfo,getCompanyWebContent,destroyProfileInfo };
