import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";
//import MockService from "@/core/mock/mock.service";
//import { VERIFY_AUTH } from "@/core/services/store/auth.module";
//import { RESET_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import VueScrollactive from 'vue-scrollactive';
ApiService.init();
Vue.config.productionTip = false;

// Global 3rd party plugins
//import "popper.js";
//import "tooltip.js";
import Toasted from 'vue-toasted';
//import PerfectScrollbar from "perfect-scrollbar";
//window.PerfectScrollbar = PerfectScrollbar;
//import ClipboardJS from "clipboard";
//window.ClipboardJS = ClipboardJS;
import VueLazyload from 'vue-lazyload'

// Vue 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import vuetify from "@/core/plugins/vuetify";
//import "@/core/plugins/portal-vue";
import "@/core/plugins/bootstrap-vue";
//import "@/core/plugins/perfect-scrollbar";
//import "@/core/plugins/highlight-js";
//import "@/core/plugins/inline-svg";
//import "@/core/plugins/apexcharts";
//import "@/core/plugins/treeselect";
import "@/core/plugins/metronic";
//import "@mdi/font/css/materialdesignicons.css";
//import vuelidate from "vuelidate";
import moment from 'moment/src/moment';
Vue.prototype.moment = moment;
// API service init


// Remove this to disable mock API
// MockService.init();

//Vue toasted init

Vue.use(Toasted,{
  duration: 4000,
  position: 'top-right',
  fullWidth:false,
  keepOnHover : true,
  action : {
    onClick : (e, toastObject) => {
      toastObject.goAway(0);
    }
  }
});

//Vuelidate init
//Vue.use(vuelidate);
Vue.use(VueScrollactive);
Vue.use(VueLazyload);

router.beforeEach((to, from, next) => {
  // // Ensure we checked auth before each page load.
  // Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
  //
  // // reset config to initial state
  // store.dispatch(RESET_LAYOUT_CONFIG);

  // console.log(from.name)
  // console.log(to.name)
    if (to.name.toLowerCase().includes("profile")) {
      if(!store.getters.isLoggedIn){
        next({ name: "login" });
        return;
      }
  }
  // console.log(store.getters.isLoggedIn)
  // if (!store.getters.isLoggedIn) {
  //   next({ name: "dashboard" });
  //   return;
  // }

  next();

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount("#app");
