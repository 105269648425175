// Keenthemes plugins
import KTUtil from "@/assets/js/components/util.js";
window.KTUtil = KTUtil;

// import KTCard from "@/assets/js/components/card.js";
// window.KTCard = KTCard;

//import KTCookie from "@/assets/js/components/cookie.js";
//window.KTCookie = KTCookie;

//import KTDialog from "@/assets/js/components/dialog.js";
//window.KTDialog = KTDialog;

import KTHeader from "@/assets/js/components/header.js";
window.KTHeader = KTHeader;

//import KTImageInput from "@/assets/js/components/image-input.js";
//window.KTImageInput = KTImageInput;

//import KTMenu from "@/assets/js/components/menu.js";
//window.KTMenu = KTMenu;

//import KTOffcanvas from "@/assets/js/components/offcanvas.js";
//window.KTOffcanvas = KTOffcanvas;

import KTScrolltop from "@/assets/js/components/scrolltop.js";
window.KTScrolltop = KTScrolltop;

import KTToggle from "@/assets/js/components/toggle.js";
window.KTToggle = KTToggle;

//import KTWizard from "@/assets/js/components/wizard.js";
//window.KTWizard = KTWizard;

// Metronic layout base js
//import KTLayoutAside from "@/assets/js/layout/base/aside.js";
//window.KTLayoutAside = KTLayoutAside;

//import KTLayoutAsideMenu from "@/assets/js/layout/base/aside-menu.js";
//window.KTLayoutAsideMenu = KTLayoutAsideMenu;

//import KTLayoutContent from "@/assets/js/layout/base/content.js";
//window.KTLayoutContent = KTLayoutContent;

//import KTLayoutFooter from "@/assets/js/layout/base/footer.js";
//window.KTLayoutFooter = KTLayoutFooter;

import KTLayoutHeader from "@/assets/js/layout/base/header.js";
window.KTLayoutHeader = KTLayoutHeader;

//import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
//window.KTLayoutHeaderMenu = KTLayoutHeaderMenu;

import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";
window.KTLayoutHeaderTopbar = KTLayoutHeaderTopbar;

//import KTLayoutStickyCard from "@/assets/js/layout/base/sticky-card.js";
//window.KTLayoutStickyCard = KTLayoutStickyCard;

//import KTLayoutStretchedCard from "@/assets/js/layout/base/stretched-card.js";
//window.KTLayoutStretchedCard = KTLayoutStretchedCard;

//import KTLayoutSubheader from "@/assets/js/layout/base/subheader.js";
//window.KTLayoutSubheader = KTLayoutSubheader;

// Metronic layout extended js
//import KTLayoutChat from "@/assets/js/layout/extended/chat.js";
//window.KTLayoutChat = KTLayoutChat;

//import KTLayoutExamples from "@/assets/js/layout/extended/examples.js";
//window.KTLayoutExamples = KTLayoutExamples;

//import KTLayoutQuickActions from "@/assets/js/layout/extended/quick-actions.js";
//window.KTLayoutQuickActions = KTLayoutQuickActions;

//import KTLayoutQuickCartPanel from "@/assets/js/layout/extended/quick-cart.js";
//window.KTLayoutQuickCartPanel = KTLayoutQuickCartPanel;

//import KTLayoutQuickNotifications from "@/assets/js/layout/extended/quick-notifications.js";
//window.KTLayoutQuickNotifications = KTLayoutQuickNotifications;

//import KTLayoutQuickPanel from "@/assets/js/layout/extended/quick-panel.js";
//window.KTLayoutQuickPanel = KTLayoutQuickPanel;

//import KTLayoutQuickSearch from "@/assets/js/layout/extended/quick-search.js";
//window.KTLayoutQuickSearch = KTLayoutQuickSearch;

//import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
//window.KTLayoutQuickUser = KTLayoutQuickUser;

//import KTLayoutScrolltop from "@/assets/js/layout/extended/scrolltop.js";
//window.KTLayoutScrolltop = KTLayoutScrolltop;

//import KTLayoutSearch from "@/assets/js/layout/extended/search.js";
//window.KTLayoutSearch = KTLayoutSearch;
